import { DesignSurface } from "@mcp-artwork/cimdoc-types-v2";
import { DesignPreviewsType } from "../Previews";
import { useEffect, useState } from "react";
import { parseMM } from "@rendering/plasma";
import debounce from "lodash/debounce";

type UseSpaceCalculatorArgs = {
  surface: DesignSurface;
  designPreviewsType: DesignPreviewsType;
};

type Size = {
  width: number;
  height: number;
};

const getWidthHeight = (surface: DesignSurface, spaceLeft: Size): Size => {
  const surfaceWidth = parseMM(surface.width);
  const surfaceHeight = parseMM(surface.height);

  if (surfaceWidth >= surfaceHeight) {
    const width = spaceLeft.width * 0.66;
    return {
      width,
      height: (surfaceHeight * width) / surfaceWidth,
    };
  }

  const height = spaceLeft.height * 0.75;

  return {
    width: (surfaceWidth * height) / surfaceHeight,
    height,
  };
};

export function useSpaceCalculator({ surface, designPreviewsType }: UseSpaceCalculatorArgs): Size & { adjustX: number } {
  const [windowSize, setWindowSize] = useState<Size>({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const onResize = debounce(() => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }, 300);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      onResize.cancel();
    };
  }, []);

  const templatesWidth = 44 + 16 + 16;
  const previewsWidth = (() => {
    if (designPreviewsType === "panels") {
      return 250;
    }
    if (designPreviewsType === "3d") {
      return 400;
    }
    if (designPreviewsType === "scene") {
      return 400;
    }
    return 0;
  })();

  const spaceLeft: Size = { width: windowSize.width - templatesWidth - previewsWidth, height: windowSize.height };
  const { width, height } = getWidthHeight(surface, spaceLeft);

  // AdjustX to transform in the middle of the open space
  return { width, height, adjustX: previewsWidth / 2 - templatesWidth / 2 };
}
