var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { subpanelLayout } from "../subpanel/Layout";
import { replaceColorOverrides } from "../../color/colorPallete";
import { produce } from "immer";
export function handleSubpanelAsImage(_a) {
    return __awaiter(this, arguments, void 0, function* ({ subpanel, validateAndLayout, previewType, colorPalette, parentBounds, definitionTreeNodeOverride, colorOverrides, }) {
        // TODO: move this to after the layout is done so the subpanel layout can be cached.
        // TODO: to do this we need to save the color ordinals in the layout element next to the originalValue.
        const subpanelWithColorOverrides = produce(subpanel, (draft) => {
            draft.colorPalette = replaceColorOverrides(draft.colorPalette, colorOverrides);
        });
        const layoutElements = yield validateAndLayout({
            surfaceOrSubpanel: subpanelWithColorOverrides,
            previewTypeOverride: "document",
            colorPaletteParent: colorPalette,
            definitionTreeNodeOverride,
        });
        const subpanelLayoutElements = subpanelLayout({
            subpanel: subpanelWithColorOverrides,
            layoutElements,
            parentBounds,
            previewType,
            options: { definitionTreeNode: definitionTreeNodeOverride },
        });
        return subpanelLayoutElements;
    });
}
