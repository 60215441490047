import { CimpressDocument, ItemReference, MatrixTransformV2, ScaleTransformV2, SkewTransformV2 } from "@mcp-artwork/cimdoc-types-v2";
import { FusionCanvas, OnPaintArgs } from "@rendering/fusion-react";
import { Selector, TextOptions } from "@rendering/plasma";
import { DimensionCalculator } from "../DimensionCalculator";
import { useCallback, useEffect } from "react";
import { drawRotatedBoundingBoxes, drawRotatedPreviewBoxes } from "../utils/drawMeasurementData";
import { useImmer } from "use-immer";
import { TransformControls } from "./TransformControls";
import { buildInstructionsUrl, encodeCimDoc } from "@rendering/product-preview";
import { selectFirstItemFromDocument } from "../selectors/document";
import { Item } from "../Models/Item";
import { AddDocumentURL } from "../AddDocumentURL";
import { fetchDocumentFromUrl } from "../utils/API/document";

const cimDocImageExample: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "5488c212-dd7d-447c-9399-dbd619296e4d",
        name: "Front",
        width: "92mm",
        height: "54mm",
        images: [
          {
            id: "original-image",
            printUrl: "https://api.sherbert.cimpress.io/v3/assets/wnXs59SV_nwxJ3t8h264z~200/print?signature=386602b488e89cd7d824f8abb68e45a0451461e1",
            previewUrl: "https://api.sherbert.cimpress.io/v3/assets/wnXs59SV_nwxJ3t8h264z~200/webPreview?signature=386602b488e89cd7d824f8abb68e45a0451461e1",
            originalSourceUrl: "https://api.sherbert.cimpress.io/v3/assets/wnXs59SV_nwxJ3t8h264z~200?signature=386602b488e89cd7d824f8abb68e45a0451461e1",
            zIndex: 1,
            position: {
              x: "27mm",
              y: "11mm",
              width: "35mm",
              height: "26mm",
            },
            rotationAngle: "0",
          },
        ],
        decorationTechnology: "offsetOrDigital",
        colorMode: "color",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/0a3b1fba-8fa6-43e1-8c3e-a018a5eb8150/published",
};

const selector: Selector = {
  type: "page",
  number: 1,
};

const textOptions: TextOptions = {
  rtextEnabled: true,
};

export function MeasurementsPage() {
  const [scale, setScale] = useImmer<ScaleTransformV2>({ x: 1, y: 1 });
  const [skew, setSkew] = useImmer<SkewTransformV2>({ x: 0, y: 0 });
  const [transform, setTransform] = useImmer<MatrixTransformV2>({
    a: 1,
    b: 0,
    c: 0,
    d: 1,
    x: "0mm",
    y: "0mm",
  });
  const [rotationAngle, setRotationAngle] = useImmer<string>("0");
  const [onPaintArgs, setOnPaintArgs] = useImmer<OnPaintArgs | null>(null);
  const [previewBoxesIds, setPreviewBoxesIds] = useImmer<string[]>([]);
  const [boundingBoxesIds, setBoundingBoxesIds] = useImmer<string[]>([]);
  const [cimDoc, setCimDoc] = useImmer<CimpressDocument>(cimDocImageExample);

  // Update transform attributes on first element in cimdoc
  useEffect(() => {
    setCimDoc((draft) => {
      const firstElement = selectFirstItemFromDocument({ document: draft });
      firstElement.item.scale = scale;
      if (firstElement.itemType !== "itemReference") {
        // TODO: Should itemreference also have skew?
        (firstElement.item as Exclude<Item, ItemReference>).skew = skew;
      }
      firstElement.item.transform = transform;
      firstElement.item.rotationAngle = rotationAngle;
    });
  }, [scale, skew, transform, rotationAngle, previewBoxesIds]);

  const onPaint = useCallback(
    (onPaintArgs: OnPaintArgs) => {
      setOnPaintArgs(onPaintArgs);
      const { canvas, layoutResult, paintResult } = onPaintArgs[0];
      const context = canvas.getContext("2d")!;

      const { scalar } = paintResult;
      drawRotatedPreviewBoxes({
        scalar,
        layoutElements: layoutResult.elements.filter((element) => previewBoxesIds.includes(element.id)),
        context,
        color: "#25C2A0",
      });

      drawRotatedBoundingBoxes({
        scalar,
        layoutElements: layoutResult.elements.filter((element) => boundingBoxesIds.includes(element.id)),
        context,
        color: "#1BABEB",
      });
    },
    [previewBoxesIds, boundingBoxesIds],
  );

  const openInDocConsole = () => {
    const instructionsUrl = buildInstructionsUrl({ cimDocUrl: encodeCimDoc({ cimDoc }) });
    const baseURLDocConsole = `https://console.documents.cimpress.io/documentViewer?`;
    const docConsoleParams = new URLSearchParams();
    docConsoleParams.set("sourceUrl", instructionsUrl);
    window.open(`${baseURLDocConsole}${docConsoleParams.toString()}`);
  };

  const onDocumentURLAdded = async (url: string) => {
    const cimDoc = await fetchDocumentFromUrl(url);
    setCimDoc(cimDoc);
  };

  return (
    <div>
      <AddDocumentURL onDocumentURLAdded={onDocumentURLAdded} />
      <div style={{ width: 600 }}>
        <DimensionCalculator>
          {({ dimension }) => (
            <FusionCanvas
              style={{ border: "1px solid black" }}
              cimDoc={cimDoc}
              dimension={dimension}
              onError={console.error}
              onPaint={onPaint}
              referrer="fusion-demo"
              selector={selector}
              textOptions={textOptions}
            />
          )}
        </DimensionCalculator>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <TransformControls
            rotationAngle={rotationAngle}
            setRotationAngle={setRotationAngle}
            scale={scale}
            setScale={setScale}
            skew={skew}
            setSkew={setSkew}
            transform={transform}
            setTransform={setTransform}
          />
        </div>
        <div style={{ width: "50%" }}>
          {onPaintArgs?.at(0)?.layoutResult?.elements.map((element) => (
            <div key={element.id}>
              <span style={{ display: "inline-block", width: 200, maxWidth: 200 }}>{element.id}</span>
              <label>
                <input
                  type="checkbox"
                  checked={boundingBoxesIds.includes(element.id)}
                  onChange={() => {
                    setBoundingBoxesIds((draft) => {
                      if (draft.includes(element.id)) {
                        draft = draft.filter((id) => element.id !== id);
                        return draft;
                      } else {
                        draft.push(element.id);
                        return draft;
                      }
                    });
                  }}
                />
                boundingbox
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={previewBoxesIds.includes(element.id)}
                  onChange={() => {
                    setPreviewBoxesIds((draft) => {
                      if (draft.includes(element.id)) {
                        return draft.filter((id) => element.id !== id);
                      } else {
                        draft.push(element.id);
                        return draft;
                      }
                    });
                  }}
                />
                previewbox
              </label>
            </div>
          ))}
        </div>
      </div>
      <button onClick={openInDocConsole}>Open in doc console</button>
    </div>
  );
}
