import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { TextOptions, Filter, DebugOptions, ExperimentalOptions, VideoOptions } from "@rendering/plasma";
import React, { CanvasHTMLAttributes, useCallback, useEffect, useMemo } from "react";
import { useRef } from "react";
import { useFusion } from "../hooks/useFusion";
import { OnPaintArgs } from "../types/OnPaintArgs";
import { Dimension } from "../utils/getPixelSize";
import { SelectorWithOverprints } from "../types/SelectorWithOverprints";

export type FusionCanvasProps = {
  cimDoc: CimpressDocument;
  dimension: Dimension;
  textOptions: TextOptions;
  debugOptions?: DebugOptions;
  experimentalOptions?: ExperimentalOptions;
  selector: SelectorWithOverprints;
  onError: (e: unknown) => void;
  onLoading?: (loading: boolean) => void;
  onPaint: (args: OnPaintArgs) => void;
  filters?: Filter[];
  videoOptions?: VideoOptions;
  referrer: string;
} & CanvasHTMLAttributes<HTMLCanvasElement>;

export function FusionCanvas({
  cimDoc,
  dimension,
  onError,
  onPaint,
  onLoading,
  textOptions,
  selector,
  filters,
  debugOptions,
  experimentalOptions,
  videoOptions,
  referrer,
  ...rest
}: FusionCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const getCanvas = useCallback(() => {
    return canvasRef.current as HTMLCanvasElement;
  }, []);
  const selectors = useMemo(() => [selector], [selector]);

  const { loading } = useFusion({
    cimDoc,
    dimension,
    getCanvas,
    onError,
    onPaint,
    selectors,
    textOptions,
    filters,
    debugOptions,
    experimentalOptions,
    videoOptions,
    referrer,
  });

  useEffect(() => {
    onLoading?.(loading);
  }, [loading, onLoading]);

  return <canvas ref={canvasRef} {...rest} />;
}
