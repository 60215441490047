import { TileAugmenter } from "./TileAugmenter";
import { parseMM } from "../../unitHelper";
export class StackAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.alternateSize = tile.alternateSize;
        this.xOffset = tile.xOffset ? parseMM(tile.xOffset) : 0;
        this.yOffset = tile.yOffset ? parseMM(tile.yOffset) : 0;
        this.tileWidth = 2 * (this.defpanelWidth + 2 * this.xOffset);
        this.tileHeight = 2 * (this.defpanelHeight + 2 * this.yOffset);
    }
    /// Stack Tile will be created as follows
    /// | yoffset
    /// | xoffset |------pattern-1-------------------| xoffset | xoffset |------pattern-1-<alternate?>------| xoffset |
    /// | yoffset
    /// | yoffset
    /// | xoffset |------pattern-1-<alternate?>------| xoffset | xoffset |------pattern-1-------------------| xoffset |
    /// | yoffset
    augmentTilePatterns() {
        const patternWidth = this.defpanelWidth;
        const patternHeight = this.defpanelHeight;
        const pattern1Position = this.createBounds(this.xOffset, this.yOffset, patternWidth, patternHeight);
        this.augmentPattern(pattern1Position);
        const threeXOffset = 3 * this.xOffset;
        const threeYOffset = 3 * this.yOffset;
        if (this.alternateSize) {
            const xOffset2 = 1.25 * patternWidth + threeXOffset;
            const yOffset2 = 0.25 * patternHeight + this.yOffset;
            const pattern2Position = this.createBounds(xOffset2, yOffset2, patternWidth / 2, patternHeight / 2);
            this.augmentPattern(pattern2Position, 0.5);
            const xOffset3 = 0.25 * patternWidth + this.xOffset;
            const yOffset3 = 1.25 * patternHeight + threeYOffset;
            const pattern3Position = this.createBounds(xOffset3, yOffset3, patternWidth / 2, patternHeight / 2);
            this.augmentPattern(pattern3Position, 0.5);
        }
        else {
            const pattern2Position = this.createBounds(patternWidth + threeXOffset, this.yOffset, patternWidth, patternHeight);
            this.augmentPattern(pattern2Position);
            const pattern3Position = this.createBounds(this.xOffset, patternHeight + threeYOffset, patternWidth, patternHeight);
            this.augmentPattern(pattern3Position);
        }
        const pattern4Position = this.createBounds(patternWidth + threeXOffset, patternHeight + threeYOffset, patternWidth, patternHeight);
        this.augmentPattern(pattern4Position);
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
