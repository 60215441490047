import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { FormEvent, useState } from "react";
import { FusionCanvas } from "../../FusionCanvas";
import { UseLayoutState } from "../../hooks/useLayout";
import { findOverprintsInDocument } from "../../utils/overprint";
import { getRandomLoremIpsum } from "./loremIpsum";
import { PerformanceResults } from "./PerformanceResults";
import CSS from "./SimpleTextDemo.module.css";

const textToDocument = (text: string): CimpressDocument => {
  return {
    version: "2",
    deleted: false,
    document: {
      panels: [
        {
          id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
          name: "fusion-text-panel",
          width: "50mm",
          height: "50mm",
          textAreas: [
            {
              id: "fusion-textarea-demo",
              position: {
                x: "0mm",
                y: "0mm",
                width: "50mm",
                height: "50mm",
              },
              zIndex: 1,
              content: [
                {
                  content: `${text}`,
                  // @ts-ignore
                  type: "inline",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontSize: "13pt",
                  color: "rgb(#238bf3)",
                },
              ],
            },
          ],
          decorationTechnology: "offsetOrDigital",
        },
      ],
    },
    fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
  };
};

// @ts-ignore
const selectTextFromDocument = (document: CimpressDocument): string => document.document.panels[0].textAreas[0].content[0].content;

export function SimpleTextDemo() {
  const pixelSize = `${0.3 / Math.ceil(window.devicePixelRatio)}mm`;
  const [document, setDocument] = useState<CimpressDocument>(() => textToDocument(""));
  const text = selectTextFromDocument(document);
  const [layoutResult, setLayoutResult] = useState<UseLayoutState>();
  const [serverSideRenderingLayoutResult, setServerSideRenderingLayoutResult] = useState<UseLayoutState>();

  const onLayout = (result: UseLayoutState) => setLayoutResult(result);
  const onServersideLayout = (result: UseLayoutState) => setServerSideRenderingLayoutResult(result);

  const onTextChange = (e: FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length > 1000) {
      return;
    }
    setDocument(textToDocument(e.currentTarget.value));
  };

  return (
    <div>
      <div className={CSS.Input}>
        <label>Input: (max 1000 character)</label>
        <textarea className={CSS.TextArea} value={text} onInput={onTextChange} placeholder="Start typing" maxLength={1000} />
        <div>
          <button
            className={CSS.Button}
            onClick={() => {
              setDocument(textToDocument("Hello world"));
            }}
          >
            Hello world
          </button>
          <button
            className={CSS.Button}
            onClick={() => {
              setDocument(textToDocument(getRandomLoremIpsum()));
            }}
          >
            Lorem ipsum
          </button>
        </div>
      </div>
      {text && (
        <PerformanceResults
          clientSide={layoutResult?.layoutResult?.debugInfo?.timers.total ?? 0}
          serverSide={serverSideRenderingLayoutResult?.layoutResult?.debugInfo?.timers.total ?? 0}
        />
      )}
      <div className={CSS.Row}>
        <div className={CSS.RenderContainer}>
          {text && <h2>Client side</h2>}
          <FusionCanvas
            input={{
              pixelSize,
              document,
              overprints: findOverprintsInDocument(document),
              selector: { id: "fusion-textarea-demo", type: "item" },
              textOptions: {
                rtextEnabled: true,
              },
              debugOptions: {
                timers: true,
              },
              referrer: "fusion-demo",
            }}
            onLayout={onLayout}
          />
        </div>
        <div className={CSS.RenderContainer}>
          {text && serverSideRenderingLayoutResult && <h2>Server side</h2>}
          <FusionCanvas
            input={{
              pixelSize,
              document,
              overprints: findOverprintsInDocument(document),
              selector: { id: "fusion-textarea-demo", type: "item" },
              forceFallback: { breakCache: true },
              debugOptions: { timers: true },
              referrer: "fusion-demo",
            }}
            onLayout={onServersideLayout}
          />
        </div>
      </div>
    </div>
  );
}
