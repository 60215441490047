import throttle from "lodash/throttle";
import { useMemo, useEffect } from "react";
import classNames from "classnames";
import CSS from "./ColorPickerInput.module.css";
import { CMYKPickerInput } from "./CMYKPickerInput";

type ColorPickerInputProps = {
  color: string;
  onColorChange: (value: string) => void;
  className?: string;
};

export function ColorPickerInput({ color, onColorChange, className }: ColorPickerInputProps) {
  const { throttled, onInput } = useMemo(() => {
    const onThrottledColorChange = throttle((value: string) => {
      onColorChange(`rgb(${value})`);
    }, 200);

    return {
      onInput(e: React.FormEvent<HTMLInputElement>) {
        const { value } = e.currentTarget;
        onThrottledColorChange(value);
      },
      throttled: onThrottledColorChange,
    };
  }, [onColorChange]);

  useEffect(() => {
    return () => {
      throttled.cancel();
    };
  }, [throttled]);

  if (color.startsWith("cmyk")) {
    return <CMYKPickerInput color={color} onColorChange={onColorChange} className={className} />;
  }

  return <input className={classNames(CSS.Input, className)} type="color" onInput={onInput} value={color} />;
}
