import { TapestryAugmenter } from "./TapestryAugmenter";
import { MultiStackAugmenter } from "./MultiStackAugmenter";
import { HalfbrickAugmenter } from "./HalfbrickAugmenter";
import { StackAugmenter } from "./StackAugmenter";
import { HexagonTileAugmenter } from "./HexagonTileAugmeter";
import { ZigZagAugmenter } from "./ZigZagAugmenter";
import { QueueAugmenter } from "./QueueAugmenter";
export class TileAugmenterFactory {
    static getAugmenter(tile, defPanel) {
        const tileType = tile.type;
        const tileBorder = tile.border;
        const tileBackgroundColor = tile.backgroundColor;
        const tileBordercolor = tile.borderColor;
        switch (tileType) {
            case "halfbrick":
                return new HalfbrickAugmenter(tile, defPanel);
            case "tapestry":
                return new TapestryAugmenter(tile, defPanel);
            case "stack":
                return new StackAugmenter(tile, defPanel);
            case "multistack":
                return new MultiStackAugmenter(tile, defPanel);
            case "hexagon":
                return new HexagonTileAugmenter(tile, defPanel);
            case "zigzag":
                return new ZigZagAugmenter(tile, defPanel);
            case "queue":
                return new QueueAugmenter(tile, defPanel);
            default:
                // add default behavior - IFF undefined
                if (!tileType) {
                    const defaultTile = {
                        type: "stack",
                        alternateSize: false,
                        border: tileBorder,
                        borderColor: tileBordercolor,
                        backgroundColor: tileBackgroundColor,
                    };
                    return new StackAugmenter(defaultTile, defPanel);
                }
                throw new Error(`Invalid tile type:${tileType}`);
        }
    }
}
