import { isTransparent } from "./isTransparent";
export function getStrokeStyle(operation, overprint) {
    var _a, _b, _c;
    // First check if the operation has an overprint
    if (((_a = operation.stroke) === null || _a === void 0 ? void 0 : _a.overprints) && operation.stroke.overprints.length > 0) {
        if (overprint) {
            // White for overprint, black for no overprint
            return operation.stroke.overprints.some((o) => o.name === overprint) ? "#ffffff" : "#000000";
        }
        // If no overprint is specified, use the last overprint in the list
        return operation.stroke.overprints[operation.stroke.overprints.length - 1].display;
    }
    // If the operation has no overprints, check if it has a stroke color
    if (((_c = (_b = operation.stroke) === null || _b === void 0 ? void 0 : _b.fill) === null || _c === void 0 ? void 0 : _c.type) === "color" && operation.stroke.fill.display) {
        if (overprint) {
            // White for overprint, black for no overprint
            if (operation.stroke.fill.name === overprint) {
                return "#ffffff";
            }
            // If the stroke color is transparent, don't draw anything for overprint
            if (isTransparent(operation.stroke.fill.display)) {
                return undefined;
            }
            return "#000000";
        }
        return operation.stroke.fill.display;
    }
    return undefined;
}
