/**
 * Checks if the given rgba color string represents a transparent color.
 *
 * @param {string} color - The color string in rgba format.
 * @returns {boolean} - Returns true if the color is transparent, otherwise false.
 */
export function isTransparent(color) {
    const rgbaRegex = /rgba?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*([0-9.]+)\s*\)/i;
    const rgbaMatch = color.match(rgbaRegex);
    if (rgbaMatch && rgbaMatch[1]) {
        const alpha = parseFloat(rgbaMatch[1]);
        return alpha === 0;
    }
    return false;
}
