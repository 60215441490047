import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import throttle from "lodash/throttle";
import { useCloseOnOutside } from "../../hooks/useCloseOnOutside";
import CSS from "./RotateInput.module.css";

type RotateInputProps = {
  value: number;
  onChange: (string: string) => void;
  className?: string;
};

export function RotateInput({ value, onChange, className }: RotateInputProps) {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useCloseOnOutside({ getElement: () => containerRef.current, callback: () => setOpen(false) });

  const { throttled, onInput } = useMemo(() => {
    const onThrottledInput = throttle((value: string) => {
      onChange(value);
    }, 20);

    return {
      onInput(e: React.FormEvent<HTMLInputElement>) {
        const { value } = e.currentTarget;
        onThrottledInput(value);
      },
      throttled: onThrottledInput,
    };
  }, [onChange]);

  useEffect(() => {
    return () => {
      throttled.cancel();
    };
  }, [throttled]);

  return (
    <div className={classNames(CSS.Container, className)} ref={containerRef}>
      <button className={CSS.Button} onClick={() => setOpen(!open)}>
        Rotate
      </button>
      {open && (
        <div className={CSS.RangeContainer}>
          <input defaultValue={value} min={-180} max={180} step={1} className={CSS.Range} type="range" onInput={onInput} />
          {value}
        </div>
      )}
    </div>
  );
}
