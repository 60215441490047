import { sanitizeOverprint } from "@rendering/plasma";
import memoize from "lodash.memoize";
import { applyClip } from "./paint/Clip";
import { computeDimensions, getLayoutBounds } from "./paint/dimensions/calculator";
import { runFilters } from "./paint/Filter";
import { paintImage, paintSSRImage } from "./paint/Image";
import { paintPaths } from "./paint/Path";
import { log } from "./utils/log";
import { timestamp } from "./utils/time";
import { releaseCanvasses } from "./utils/releaseCanvasses";
export const getOffscreenCanvas = memoize((id) => {
    const paintingCanvas = document.createElement("canvas");
    paintingCanvas.id = `fusion-offscreen-${id}-${Math.random()}`; // Add random to make sure the id is unique
    const paintingContext = paintingCanvas.getContext("2d");
    return paintingContext;
});
export function paint({ canvasContext, overprint, layoutResult, pixelSize, paddingPx, debugOptions, overrides }) {
    var _a;
    log({ message: "calling paint function", enabled: !!(debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.log), objects: { canvasContext, layoutResult, pixelSize, debugOptions, overprint } });
    const start = timestamp(!!(debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.timers));
    const offscreenCanvasCreator = overrides ? overrides.getAdditionalCanvas : getOffscreenCanvas;
    const cacheContext = offscreenCanvasCreator("cache");
    const paintingCanvas = canvasContext.canvas;
    const cacheCanvas = cacheContext.canvas;
    const { width, height, scalar } = computeDimensions({ pixelSize, layoutResult, ssr: !!(debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.ssrDimensions) });
    log({ message: "computed dimensions", enabled: !!(debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.log), objects: { width, height, scalar } });
    // Set the input canvas to the computed dimensions
    canvasContext.canvas.width = width;
    canvasContext.canvas.height = height;
    canvasContext.save();
    // Adjust painting size to device pixel ratio
    paintingCanvas.width = width;
    paintingCanvas.height = height;
    if (paddingPx) {
        paintingCanvas.width += Math.ceil(paddingPx) * 2;
        paintingCanvas.height += Math.ceil(paddingPx) * 2;
    }
    // Clear the provided canvas
    canvasContext.clearRect(0, 0, paintingCanvas.width, paintingCanvas.height);
    const bounds = getLayoutBounds(layoutResult);
    canvasContext.save();
    try {
        log({ message: "bounds used", enabled: !!(debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.log), objects: { bounds } });
        if (paddingPx) {
            canvasContext.transform(1, 0, 0, 1, Math.ceil(paddingPx), Math.ceil(paddingPx));
        }
        // For item previews of ssr fallback elements, bypass the standard rendering logic in order
        // to ensure the image is drawn at exactly its native size. Otherwise this will result in blurriness
        // due to rounding and transforming the htmlcanvas context.
        if (layoutResult.layoutType === "item" &&
            layoutResult.elements[0].status.mode === "server" &&
            layoutResult.elements[0].renderingOperation.type === "drawImage") {
            paintSSRImage({ context: canvasContext, layout: layoutResult.elements[0].renderingOperation, overprint: sanitizeOverprint(overprint) });
        }
        else {
            canvasContext.transform(scalar, 0, 0, scalar, 0, 0);
            canvasContext.transform(1, 0, 0, 1, -bounds.left, -bounds.top);
            paintElements(layoutResult.elements, canvasContext, 0, cacheCanvas, cacheContext, pixelSize, sanitizeOverprint(overprint));
        }
    }
    finally {
        canvasContext.restore();
    }
    // Apply filters to the result before draw it
    if ((layoutResult.filters && layoutResult.filters.length > 0) || overprint) {
        const filters = [...((_a = layoutResult.filters) !== null && _a !== void 0 ? _a : [])];
        if (overprint) {
            // All overprints are composited using black + white in order to correctly apply overprint
            // knockout with overlaps. The final result to the caller should be an alpha + white mask
            // which matches renderings output and works directly with vortex
            filters.push({
                type: "colorMatrix",
                matrix: [
                    [0, 0, 0, 1],
                    [0, 0, 0, 1],
                    [0, 0, 0, 1],
                    [0, 0, 0, 0],
                    [1, 1, 1, 0],
                ],
            });
        }
        const filteredResult = runFilters(filters, paintingCanvas);
        // Before drawing the filtered result, clear the canvas
        canvasContext.clearRect(0, 0, paintingCanvas.width, paintingCanvas.height);
        canvasContext.drawImage(filteredResult, 0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
        releaseCanvasses([filteredResult]);
    }
    canvasContext.restore();
    return Object.assign({ scalar }, ((debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.timers) && start
        ? {
            debugInfo: {
                timers: {
                    paint: timestamp(true) - start,
                },
            },
        }
        : {}));
}
export function paintElements(elements, context, recursionDepth, cacheCanvas, cacheContext, pixelSize, overprint) {
    if (recursionDepth >= 99) {
        throw Error("Maximum recursion depth reached");
    }
    for (const element of elements) {
        if (element.renderingOperation.type === "drawImage") {
            const layout = element.renderingOperation;
            // Bounds may be bounds of the clip instead of the item. We want to draw the image in the rectangle
            // that is generated using the image bounds, not clip bounds.
            paintImage({ context, layout, bounds: layout.boundingBox, overprint, cacheCanvas, cacheContext, pixelSize });
        }
        if (element.renderingOperation.type === "drawPaths") {
            const layout = element.renderingOperation;
            paintPaths({ context, layout, overprint, pixelSize });
        }
        if (element.renderingOperation.type === "group") {
            const layout = element.renderingOperation;
            context.save();
            try {
                applyClip({ context, clip: layout.crop });
                applyClip({ context, clip: layout.clip });
                context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
                const transform = layout.transform;
                // Note that context.transform multiplies this transform by the current transform
                // (instead of replacing it, like context.setTransform does.)
                context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);
                paintElements(layout.contents, context, recursionDepth + 1, cacheCanvas, cacheContext, pixelSize, overprint);
            }
            finally {
                context.restore();
            }
        }
    }
}
