import { isTransparent } from "./isTransparent";
export function getFillStyle(operation, overprint) {
    var _a;
    // First check if the operation has an overprint
    if (operation.overprints && operation.overprints.length > 0) {
        if (overprint) {
            // White for overprint, black for no overprint
            return operation.overprints.some((o) => o.name === overprint) ? "#ffffff" : "#000000";
        }
        // If no overprint is specified, use the last overprint in the list
        return operation.overprints[operation.overprints.length - 1].display;
    }
    // If the operation has no overprints, check if it has a fill color
    if (((_a = operation.fill) === null || _a === void 0 ? void 0 : _a.type) === "color" && operation.fill.display) {
        if (overprint) {
            // White for overprint, black for no overprint
            if (operation.fill.name === overprint) {
                return "#ffffff";
            }
            // If the fill color is transparent, don't draw anything for overprint
            if (isTransparent(operation.fill.display)) {
                return undefined;
            }
            return "#000000";
        }
        return operation.fill.display;
    }
    return undefined;
}
