import { applyClip } from "./Clip";
import { Matrix, } from "@rendering/plasma";
import { getOffscreenCanvas, paint } from "../PaintEngine";
import { MAX_CANVAS_DIMENSION } from "./dimensions/calculator";
import { getStrokeStyle } from "./helpers/getStrokeStyle";
import { getFillStyle } from "./helpers/getFillStyle";
/* I couldn't find documentation for the maximum/minimum values for the values of each of the (x, y, width, height) parameters
 * of the fillRect() function. But through manual testing, I found that powers of 2 exceeding the 25th power seem to break the function
 * completely. From some testing it looks like this value still doesn't change with the canvases of different sizes.
 */
const FILLRECT_MAX_DIMENSION = Math.pow(2, 25);
function convertLineJoinToCanvas(join) {
    switch (join) {
        case "mitre":
            return "miter";
        default:
            return join;
    }
}
function createLinearGradientFill(linearGradient, context) {
    const gradientFill = context.createLinearGradient(linearGradient.start.x, linearGradient.start.y, linearGradient.end.x, linearGradient.end.y);
    linearGradient.stops.forEach((stop) => {
        if (stop.color.display) {
            gradientFill.addColorStop(stop.offset, stop.color.display);
        }
    });
    return { fill: gradientFill, transform: linearGradient.transform };
}
function createRadialGradientFill(radialGradient, context) {
    const gradientFill = context.createRadialGradient(radialGradient.start.x, radialGradient.start.y, radialGradient.startRadius, radialGradient.end.x, radialGradient.end.y, radialGradient.endRadius);
    radialGradient.stops.forEach((stop) => {
        if (stop.color.display) {
            gradientFill.addColorStop(stop.offset, stop.color.display);
        }
    });
    return { fill: gradientFill, transform: radialGradient.transform };
}
function createPatternFill(pattern, context, pixelSize) {
    const patternContext = getOffscreenCanvas("patterns");
    const input = {
        canvasContext: patternContext,
        layoutResult: pattern.layout,
        // Modifying the pixel size here is equivalent to changing the resolution of the pattern. Here, we are rendering the pattern
        // at the actual dimensions in the final canvas. Normally, the canvasScalar is quite a big number, something like 14. We are doing
        // this because a pattern drawn at a small resolution then scaled up causes big quality issues.
        pixelSize,
    };
    const paintOutput = paint(input);
    const canvasPattern = context.createPattern(patternContext.canvas, pattern.repetition);
    const patternTransform = pattern.transform;
    // We need to apply the canvas scalar to the absolute components of the matrix since the compositing canvas is drawn WITHOUT the scalar applied.
    // See how the compositing canvas is drawn in the paintPath() function when fillIsPattern === true.
    patternTransform.x *= paintOutput.scalar;
    patternTransform.y *= paintOutput.scalar;
    return { fill: canvasPattern, transform: patternTransform, patternCanvasScalar: paintOutput.scalar };
}
function setFilterContext(context, path) {
    if (path.effects != null) {
        path.effects.forEach((effect) => {
            if (effect.type === "blur") {
                addFilter(context, `blur(${effect.radius}mm)`);
            }
        });
    }
}
function addFilter(context, command) {
    if (context.filter === "none") {
        context.filter = command;
    }
    else {
        context.filter = `${context.filter} ${command}`;
    }
}
function getCompositingCanvas(context, fill, pixelSize) {
    if (fill.type === "radialGradient" || fill.type === "linearGradient" || fill.type === "pattern") {
        let externalFill;
        if (fill.type === "radialGradient") {
            externalFill = createRadialGradientFill(fill, context);
        }
        else if (fill.type === "linearGradient") {
            externalFill = createLinearGradientFill(fill, context);
        }
        else {
            externalFill = createPatternFill(fill, context, pixelSize);
        }
        const oCtx = getOffscreenCanvas("path-compositing");
        const oCnv = oCtx.canvas;
        // Ideally, the dimensions of the offscreen canvas should be the smallest as they can be to improve performance. However, calculating these canvas dimensions is hard because
        // we get the inverse of the current transform to get the inverse scale factor, then multiply that scale factor with the current canvas dimensions to get the offscreen canvas dimensions.
        // This is because the offscreen canvas gets composited to the main canvas with the main canvas transform, so we need the dimensions before the transform.
        // This method is accurate in most cases, but the problem is that the scale factors of the current transform matrix may not always represent the true dimensions of the canvas (we say canvas here because some items may fill up the entire panel).
        // For example, if the current context matrix scaled up by 3, it is not always the case that the offscreen canvas should be the current canvas dimensions divided by 3. This is because some item references with
        // cropFractions also use a scale transform, but this does not change the dimensions of the item.
        // Luckily the max canvas dimension may be at most MAX_CANVAS_DIMENSION (which is 4000), this seems to perform reasonably well and guarantees that gradients/patterns won't be clipped
        // when compositing onto the main canvas.
        oCnv.width = MAX_CANVAS_DIMENSION;
        oCnv.height = MAX_CANVAS_DIMENSION;
        oCtx.clearRect(0, 0, MAX_CANVAS_DIMENSION, MAX_CANVAS_DIMENSION);
        oCtx.setTransform(externalFill.transform.toDOMMatrix());
        oCtx.fillStyle = externalFill.fill;
        // The intent here is to have the gradient fill the entire canvas, with fillRect(0, 0, width, height). But if a transform was applied,
        // this may not always fill the canvas, since the rectangle from fillRect is also transformed, and fillStyles do not extend beyond the dimensions.
        // This is a 'hack' to ensure that the gradient is always extended beyond the canvas even with transforms, by creating a massive rectangle extending
        // beyond all sides of the canvas. Interestingly, this seems to be very performant.
        oCtx.fillRect(-FILLRECT_MAX_DIMENSION / 2, -FILLRECT_MAX_DIMENSION / 2, FILLRECT_MAX_DIMENSION, FILLRECT_MAX_DIMENSION);
        return { compositingCanvasContext: oCtx, patternCanvasScalar: externalFill.patternCanvasScalar };
    }
    return { compositingCanvasContext: undefined, patternCanvasScalar: undefined };
}
function contextFill(context, path, fillStyle, fillRule) {
    context.fillStyle = fillStyle;
    context.fill(path, fillRule);
}
function contextStroke(context, path, properties) {
    context.strokeStyle = properties.strokeStyle;
    context.lineWidth = properties.layoutStroke.width;
    context.lineCap = properties.layoutStroke.lineCap;
    context.lineJoin = convertLineJoinToCanvas(properties.layoutStroke.lineJoin);
    if (properties.layoutStroke.dashArray) {
        context.setLineDash(properties.layoutStroke.dashArray);
    }
    context.stroke(path);
}
// Paints the path and takes into account if a overprint channel is requested
function paintPath(context, operation, pixelSize, overprint) {
    var _a;
    const path = new Path2D(operation.path);
    const canvasWidth = context.canvas.width;
    const canvasHeight = context.canvas.height;
    let fillStyle = getFillStyle(operation, overprint);
    const fillIsPattern = ((_a = operation.fill) === null || _a === void 0 ? void 0 : _a.type) === "pattern" ? true : false;
    let strokeStyle = getStrokeStyle(operation, overprint);
    // This is where the radial gradients are drawn
    let compositingFillCanvasCtx;
    let compositingStrokeCanvasCtx;
    // The radial gradients here are then masked by the fill/stroke
    let fillMaskCanvasCtx;
    let strokeMaskCanvasCtx;
    let patternCanvasScalar;
    // Determine the fill style
    if (operation.fill) {
        const compositingCanvasResponse = getCompositingCanvas(context, operation.fill, pixelSize);
        compositingFillCanvasCtx = compositingCanvasResponse.compositingCanvasContext;
        patternCanvasScalar = compositingCanvasResponse.patternCanvasScalar;
    }
    // Since a separate canvas may be used to draw the fill and then composited into the main canvas, there needs to exist
    // some kind of fill for the composite operation to fill the shape. While in theory the fill color should not be a factor,
    // having a non-white color produces some artifacting issues, probably from the compositing operation.
    if (compositingFillCanvasCtx !== undefined) {
        fillStyle = "#FFFFFFFF";
        fillMaskCanvasCtx = getOffscreenCanvas("path-fill-mask");
        fillMaskCanvasCtx.canvas.width = canvasWidth;
        fillMaskCanvasCtx.canvas.height = canvasHeight;
        fillMaskCanvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
        fillMaskCanvasCtx.setTransform(context.getTransform());
    }
    if (fillStyle) {
        let fillContext;
        if (fillMaskCanvasCtx !== undefined) {
            fillContext = fillMaskCanvasCtx;
        }
        else {
            fillContext = context;
        }
        contextFill(fillContext, path, fillStyle, operation.fillRule);
    }
    if (compositingFillCanvasCtx !== undefined && fillMaskCanvasCtx !== undefined) {
        context.save();
        context.resetTransform();
        // needs to be source-over and not source-in, since there is the factor of transparency in gradient color stops
        context.globalCompositeOperation = "source-over";
        // mask the gradient
        fillMaskCanvasCtx.globalCompositeOperation = "source-in";
        if (fillIsPattern) {
            fillMaskCanvasCtx.save();
            // We need to get rid of the canvasScalar in the transform. This is because the item with the pattern may have some scale
            // translation, and that still needs to be applied to the compositing canvas.
            // On a side note, this is poor software design to have a single matrix transform containing possibly many unrelated transformations. Because the
            // current case is simple enough I'm able to extract the scale transform. At some point, we should rewrite transform handling in plasma/neon to behave
            // more like the instructions service: a stack-based and isolated context using PushState/PopState. This should also make it easier to develop features
            // between fusion and Rendering.
            fillMaskCanvasCtx.scale(1 / patternCanvasScalar, 1 / patternCanvasScalar);
            const decomposedValues = getCurrentContextDecomposedValues(fillMaskCanvasCtx);
            fillMaskCanvasCtx.resetTransform();
            // This essentially applies the item transforms (not pattern-local transforms) to the pattern.
            let patternTransform = Matrix.scale(decomposedValues.scale.x, decomposedValues.scale.y);
            patternTransform = Matrix.multiply(patternTransform, Matrix.rotation(decomposedValues.rotation));
            patternTransform = Matrix.multiply(patternTransform, Matrix.translate(decomposedValues.translation.x, decomposedValues.translation.y));
            fillMaskCanvasCtx.setTransform(new DOMMatrix([patternTransform.a, patternTransform.b, patternTransform.c, patternTransform.d, patternTransform.x, patternTransform.y]));
        }
        fillMaskCanvasCtx.drawImage(compositingFillCanvasCtx.canvas, 0, 0);
        if (fillIsPattern) {
            fillMaskCanvasCtx.restore();
        }
        // draw the result
        context.drawImage(fillMaskCanvasCtx.canvas, 0, 0);
        context.restore();
    }
    // Draw strokes if they exist
    if (operation.stroke && operation.stroke.width > 0) {
        const stroke = operation.stroke;
        // Determine the fill style
        if (stroke.fill) {
            compositingStrokeCanvasCtx = getCompositingCanvas(context, stroke.fill, pixelSize).compositingCanvasContext;
        }
        // Similar to the logic for fills above
        if (compositingStrokeCanvasCtx !== undefined) {
            strokeStyle = "white";
            strokeMaskCanvasCtx = getOffscreenCanvas("path-stroke-mask");
            strokeMaskCanvasCtx.canvas.width = canvasWidth;
            strokeMaskCanvasCtx.canvas.height = canvasHeight;
            strokeMaskCanvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
            strokeMaskCanvasCtx.setTransform(context.getTransform());
        }
        if (strokeStyle) {
            let strokeContext;
            if (strokeMaskCanvasCtx !== undefined) {
                strokeContext = strokeMaskCanvasCtx;
            }
            else {
                strokeContext = context;
            }
            contextStroke(strokeContext, path, { layoutStroke: stroke, strokeStyle: strokeStyle });
        }
        if (compositingStrokeCanvasCtx !== undefined && strokeMaskCanvasCtx !== undefined) {
            context.save();
            context.resetTransform();
            context.globalCompositeOperation = "source-in";
            strokeMaskCanvasCtx.globalCompositeOperation = "source-in";
            strokeMaskCanvasCtx.drawImage(compositingStrokeCanvasCtx.canvas, 0, 0);
            context.drawImage(strokeMaskCanvasCtx.canvas, 0, 0);
            context.restore();
        }
    }
}
export function paintPaths({ context, layout, overprint, pixelSize }) {
    context.save();
    try {
        applyClip({ context, clip: layout.clip });
        context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
        for (const operation of layout.paths) {
            // Scale the operation matrix
            const transform = operation.transform;
            context.save();
            try {
                setFilterContext(context, operation);
                // Apply matrix transform
                context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);
                paintPath(context, operation, pixelSize, overprint);
            }
            finally {
                context.restore();
            }
        }
    }
    finally {
        context.restore();
    }
}
function getCurrentContextDecomposedValues(context) {
    const transform = context.getTransform();
    const matrix = new Matrix(transform.a, transform.b, transform.c, transform.d, transform.e, transform.f);
    return Matrix.decomposeAffineTransform(matrix);
}
