import { TileAugmenter } from "./TileAugmenter";
export class ZigZagAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.tileDirection = tile.direction;
        this.tileWidth = this.defpanelWidth;
        this.tileHeight = this.defpanelHeight * 2;
    }
    /**
     * ZigZagPattern will appear as follow
     * |------patern------|
     * |--pattern-mirror--|
     */
    augmentTilePatterns() {
        const pattern1Position = this.createBounds(0, 0, this.defpanelWidth, this.defpanelHeight);
        this.augmentPattern(pattern1Position);
        const pattern2Position = this.createBounds(0, this.defpanelHeight, this.defpanelWidth, this.defpanelHeight);
        this.augmentPattern(pattern2Position, 1, this.tileDirection);
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
