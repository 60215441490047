import { useEffect, useRef, useState } from "react";
import { layout, Selector, LayoutInput, parseMM } from "@rendering/plasma";
import { paint } from "@rendering/neon";
import { getPageDimensions, selectFirstItemFromDocument } from "../selectors/document";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export function NonScenePreview({
  cimDoc,
  page,
  rtextEnabled,
  width,
  pixel,
}: {
  cimDoc: CimpressDocument;
  page: number;
  width: number | null;
  pixel: string | null;
  rtextEnabled: boolean;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fn = async () => {
      try {
        const getSelectorAndPixelSize = async (): Promise<{ selector: Selector; pixelSize: string }> => {
          if (pixel) {
            return { selector: { id: selectFirstItemFromDocument({ document: cimDoc }).item.id, type: "item" }, pixelSize: `${parseMM(pixel)}mm` };
          } else if (width) {
            // Select the right panel by page number (default to 1)
            const selector: Selector = { type: "page", number: page };
            const size = await getPageDimensions(selector.number, cimDoc);

            return { selector, pixelSize: `${size.width / width}mm` };
          } else {
            throw Error("Pixel or width must be specified");
          }
        };

        const { selector, pixelSize } = await getSelectorAndPixelSize();

        const input: LayoutInput = {
          document: cimDoc,
          selector,
          textOptions: { rtextEnabled },
          pixelSize,
          referrer: "fusion-demo",
        };

        const layoutResult = await layout(input);
        if (!layoutResult) {
          throw new Error("Layout result is empty!");
        }

        if (canvasRef.current) {
          paint({
            layoutResult,
            canvasContext: canvasRef.current?.getContext("2d") as CanvasRenderingContext2D,
            pixelSize,
            debugOptions: {
              ssrDimensions: true,
            },
          });

          // Alert that this page has finished painting
          document.dispatchEvent(new Event("completePaint"));

          // @ts-ignore
          window.completePaint = true;
        }
      } catch (error: any) {
        const message: string = (error as Error).message ?? "Unknown error!";
        const failEvent = new Event("fusionError");
        // @ts-ignore
        failEvent.errorMessage = message;
        document.dispatchEvent(failEvent);

        // @ts-ignore
        window.fusionError = message;

        setError(message);
      }
    };
    fn();
  }, [cimDoc, width, page, rtextEnabled]);

  return (
    <div>
      {error && <div>Error: {error} </div>}
      <canvas id="display" ref={canvasRef} width="0" height="0" />
    </div>
  );
}
