var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOffscreenCanvas } from "./PaintEngine";
import { applyClip } from "./paint/Clip";
import { paintImage } from "./paint/Image";
import { paintPaths } from "./paint/Path";
import { getDestinationAndSourceDimensions } from "./paint/dimensions/ImageDestinationAndSourceDimensions";
import { computeDimensions, getLayoutBounds } from "./paint/dimensions/calculator";
const FPS = 30;
const FRAME_INTERVAL = 1000 / FPS;
// interface AudioListEntry {
//   layout: AudioLayout;
//   element: HTMLAudioElement;
// }
const addToVideoCollection = ({ layoutElement, videoParent, videoList, }) => {
    const renderingOperation = layoutElement.renderingOperation;
    switch (renderingOperation.type) {
        case "drawVideo": {
            const video = document.createElement("video");
            if (renderingOperation.video === undefined) {
                throw Error("Could not find videoBlob");
            }
            video.src = URL.createObjectURL(renderingOperation.video);
            videoParent.appendChild(video);
            videoList.push({ element: video, layout: renderingOperation });
            break;
        }
        case "group": {
            for (const childItem of renderingOperation.contents) {
                addToVideoCollection({ layoutElement: childItem, videoParent, videoList });
            }
            break;
        }
    }
};
// const createAudioCollection = ({
//   layoutResult,
//   audioParent,
//   loaded,
// }: {
//   layoutResult: LayoutResult;
//   audioParent: HTMLElement;
//   loaded: () => void;
// }): AudioListEntry[] => {
//   const layout = layoutResult.audio;
//   if (layout) {
//     const audio = document.createElement("audio");
//     audio.addEventListener("loadeddata", () => {
//       loaded();
//     });
//     audio.src = URL.createObjectURL(layout.audio);
//     audioParent.appendChild(audio);
//     return [{ element: audio, layout }];
//   }
//   loaded();
//   return [];
// };
const createVideoCollection = ({ layoutResult, videoParent, loaded }) => {
    var _a, _b, _c;
    const videoList = [];
    for (const layoutElement of layoutResult.elements) {
        addToVideoCollection({ layoutElement, videoParent, videoList });
    }
    const readyVideos = videoList.map(() => false);
    for (let i = 0; i < videoList.length; i++) {
        videoList[i].element.addEventListener("loadeddata", () => {
            var _a, _b, _c;
            const startTimeMs = (_c = (_b = (_a = videoList[i].layout.segments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.startTimeMs) !== null && _c !== void 0 ? _c : 0;
            if (startTimeMs) {
                videoList[i].element.currentTime = startTimeMs * 1000;
            }
            readyVideos[i] = true;
            if (readyVideos.every((v) => v)) {
                loaded();
            }
        });
    }
    // TODO: Move the seeks above and below to when "play" is hit, because it needs to reset each time it's played.
    for (let i = 0; i < videoList.length; i++) {
        if (videoList[i].element.readyState >= 2) {
            const startTimeMs = (_c = (_b = (_a = videoList[i].layout.segments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.startTimeMs) !== null && _c !== void 0 ? _c : 0;
            if (startTimeMs) {
                videoList[i].element.currentTime = startTimeMs * 1000;
            }
            readyVideos[i] = true;
        }
    }
    if (readyVideos.every((v) => v)) {
        loaded();
    }
    return videoList;
};
export const createRichMediaEngine = (_a) => __awaiter(void 0, [_a], void 0, function* ({ videoParent, layoutResult, pixelSize, canvasContext }) {
    let timerRequestAnimationFrame = undefined;
    let currentFrame = undefined;
    let videoCollection;
    const videoPromise = new Promise((resolve) => {
        videoCollection = createVideoCollection({ layoutResult, videoParent, loaded: () => resolve() });
    });
    // let audioCollection: AudioListEntry[];
    // const audioPromise = new Promise<void>((resolve) => {
    //   audioCollection = createAudioCollection({ layoutResult, audioParent: videoParent, loaded: () => resolve() });
    // });
    yield videoPromise;
    // await audioPromise;
    // const previousTimeFrame = 0;
    const paintingContext = getOffscreenCanvas("richMedia");
    const cacheContext = getOffscreenCanvas("cache");
    const paintingCanvas = paintingContext.canvas;
    const cacheCanvas = cacheContext.canvas;
    const { width, height, scalar } = computeDimensions({ pixelSize, layoutResult, ssr: false });
    // Set the input canvas to the computed dimensions
    canvasContext.canvas.width = width;
    canvasContext.canvas.height = height;
    canvasContext.save();
    // Adjust painting size to device pixel ratio
    paintingCanvas.width = width;
    paintingCanvas.height = height;
    function cancelFrame() {
        if (timerRequestAnimationFrame !== undefined) {
            cancelAnimationFrame(timerRequestAnimationFrame);
            timerRequestAnimationFrame = undefined;
        }
    }
    function play() {
        const STARTING_FRAME = 0; // TODO: Make these parameters / depend on video timelines
        const ENDING_FRAME = 150;
        cancelFrame();
        currentFrame = STARTING_FRAME;
        const interval = Math.round(FRAME_INTERVAL);
        let previousFrameTime = 0;
        const animationHandler = (timestamp) => {
            const diff = Math.round(timestamp - previousFrameTime);
            if (diff >= interval) {
                previousFrameTime = timestamp;
                renderFrame();
                currentFrame = (currentFrame !== null && currentFrame !== void 0 ? currentFrame : 0) + 1;
            }
            timerRequestAnimationFrame = requestAnimationFrame(animationHandler);
            if ((currentFrame !== null && currentFrame !== void 0 ? currentFrame : 0) >= ENDING_FRAME) {
                stop();
            }
        };
        timerRequestAnimationFrame = requestAnimationFrame(animationHandler);
    }
    function stop() {
        cancelFrame();
        // for (const { element } of audioCollection) {
        //   element.pause();
        // }
    }
    function renderFrame() {
        var _a, _b, _c, _d;
        // Start things based on timelines
        for (const { element, layout } of videoCollection) {
            if (Math.floor((((_a = layout.startTimeMs) !== null && _a !== void 0 ? _a : 0) * 1000) / FPS) === currentFrame) {
                element.play();
            }
            if (((_b = layout.segments) === null || _b === void 0 ? void 0 : _b[0]) &&
                layout.segments[0].endTimeMs &&
                ((((_c = layout.startTimeMs) !== null && _c !== void 0 ? _c : 0) + layout.segments[0].endTimeMs - ((_d = layout.segments[0].startTimeMs) !== null && _d !== void 0 ? _d : 0)) * 1000) / FPS === currentFrame) {
                element.pause();
            }
        }
        // for (const { element, layout } of audioCollection) {
        //   if ((layout.startDelay ?? 0) === currentFrame) {
        //     element.play();
        //   }
        // }
        // Clear the intermediate painting canvas
        paintingContext.clearRect(0, 0, paintingCanvas.width, paintingCanvas.height);
        const bounds = getLayoutBounds(layoutResult);
        paintingContext.save();
        try {
            paintingContext.transform(scalar, 0, 0, scalar, 0, 0);
            paintingContext.transform(1, 0, 0, 1, -bounds.left, -bounds.top);
            paintElements(layoutResult.elements, paintingContext, 0, pixelSize);
        }
        finally {
            paintingContext.restore();
        }
        // Clear the provided input canvas
        canvasContext.clearRect(0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
        canvasContext.drawImage(paintingCanvas, 0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
    }
    function paintElements(elements, context, recursionDepth, pixelSize) {
        if (recursionDepth > 99) {
            throw Error("Maximum recursion depth reached");
        }
        for (const sourceElement of elements) {
            let element = sourceElement;
            if (element.renderingOperation.getAnimation) {
                element = Object.assign(Object.assign({}, element), { renderingOperation: element.renderingOperation.getAnimation((currentFrame !== null && currentFrame !== void 0 ? currentFrame : 0) * FRAME_INTERVAL) });
            }
            const bounds = element.measurementData.boundingBox;
            if (element.renderingOperation.type === "drawImage") {
                paintImage({ context, cacheContext, cacheCanvas, layout: element.renderingOperation, bounds, overprint: undefined, pixelSize });
            }
            if (element.renderingOperation.type === "drawPaths") {
                paintPaths({ context, layout: element.renderingOperation, pixelSize, overprint: undefined });
            }
            if (element.renderingOperation.type === "drawVideo") {
                paintVideo({ context, layout: element.renderingOperation, bounds, videoCollection });
            }
            if (element.renderingOperation.type === "group") {
                const layout = element.renderingOperation;
                context.save();
                try {
                    applyClip({ context, clip: layout.clip });
                    context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
                    const transform = layout.transform;
                    // Note that context.transform multiplies this transform by the current transform
                    // (instead of replacing it, like context.setTransform does.)
                    context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);
                    paintElements(layout.contents, context, recursionDepth + 1, pixelSize);
                }
                finally {
                    context.restore();
                }
            }
        }
    }
    return { play, stop };
});
function paintVideo({ context, layout, bounds, videoCollection, }) {
    var _a;
    context.save();
    try {
        const video = (_a = videoCollection.find((entry) => entry.layout === layout)) === null || _a === void 0 ? void 0 : _a.element;
        if (video === undefined) {
            throw Error("Could not find video element");
        }
        applyClip({ context, clip: layout.clip });
        context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
        // Scale the operation matrix
        const transform = layout.transform;
        // Apply matrix transform
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);
        const { sx, sy, sw, sh, dx, dy, dw, dh } = getDestinationAndSourceDimensions({
            image: { width: video.videoWidth, height: video.videoHeight },
            bounds,
            crop: layout.crop,
        });
        context.drawImage(video, sx, sy, sw, sh, dx, dy, dw, dh);
    }
    finally {
        context.restore();
    }
}
