// @ts-ignore
import { centralizedAuth } from "@cimpress/simple-auth-wrapper";
import memoize from "lodash/memoize";

export const getAuth = memoize(() => {
  return new centralizedAuth({
    clientID: "uKelL54kDy2KdD5l1n7bcuorRz1mvKOe",
    redirectRoute: window.location.pathname,
    nextUri: window.location.pathname + window.location.hash,
  });
});
