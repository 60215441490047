import { ExperimentalOptions, TextOptions, VideoOptions } from "@rendering/plasma";
import { useEffect, useRef, useState } from "react";
import CSS from "./GlobalSettings.module.css";

export type GlobalSettingsOptions = {
  log: boolean;
  timers: boolean;
  textOptions: TextOptions;
  videoEnabled: boolean;
  experimentalOptions: ExperimentalOptions;
};

type GlobalSettingsProps = {
  options: GlobalSettingsOptions;
  onChange: (options: GlobalSettingsOptions) => void;
};

export function GlobalSettings({ options, onChange }: GlobalSettingsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (open && !containerRef.current?.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <div className={CSS.Container}>
      <button className={CSS.Button} onClick={() => setOpen(!open)}>
        <span className={`${CSS.Cogwheel} ${open && CSS.ForceSpin}`}>⚙️</span>
      </button>
      {open && (
        <div className={CSS.OptionsContainer} ref={containerRef}>
          <label>
            <input type="checkbox" checked={options.log} onChange={() => onChange({ ...options, log: !options.log })} />
            Debug option: log (see browser console)
          </label>
          <label>
            <input type="checkbox" checked={options.timers} onChange={() => onChange({ ...options, timers: !options.timers })} />
            Debug option: timers (see browser console)
          </label>
          <hr style={{ width: "100%" }} />
          <label>
            <input
              type="checkbox"
              checked={options.textOptions.rtextEnabled}
              onChange={() => {
                onChange({ ...options, textOptions: { ...options.textOptions, rtextEnabled: !options.textOptions.rtextEnabled } });
              }}
            />
            TextOption: Rtext enabled
          </label>
          <hr style={{ width: "100%" }} />
          <label>
            <input
              type="checkbox"
              checked={options.videoEnabled}
              onChange={() =>
                onChange({
                  ...options,
                  videoEnabled: !options.videoEnabled,
                })
              }
            />
            Enable video
          </label>
          <hr style={{ width: "100%" }} />
        </div>
      )}
    </div>
  );
}
