var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import svgpath from "svgpath";
import { boundingBoxFromPath, computeTextAreaBounds, maximum } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { toRadians, parseMM } from "../../utils/unitHelper";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { buildItemTransform, buildTransform } from "../helpers/Transform";
import { processFilters } from "./filters/filterProcessor";
import { getDecorationBounds, getTextFieldByBlockIndex } from "./TextMeasurements";
import { translateLineJoin, translateLineCap, translateDashArray } from "./translates";
import { generateBackgroundShape } from "./textBackground/Generator";
import { parsePathData } from "../../utils/parsePathData";
export function buildRenderingOperations(_a) {
    return __awaiter(this, arguments, void 0, function* ({ textArea, textFields, result, fonts, parentBounds, textPathData, options, }) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        let operations = [];
        const areaBounds = computeTextAreaBounds({ textArea: textArea, engineResult: result });
        const extraBounds = [];
        let backgroundPreviewBox;
        let filterBounds = [];
        const positionX = textArea.position.x === undefined ? 0 : parseMM(textArea.position.x);
        const positionY = textArea.position.y === undefined ? 0 : parseMM(textArea.position.y);
        if (areaBounds === undefined) {
            return { operations: [], transform: Matrix.identity(), filterBounds, clipTransform: Matrix.identity() };
        }
        let globalTransform = buildTransform({
            bounds: areaBounds,
            rotationAngle: textArea.rotationAngle,
            scale: textArea.scale,
            skew: textArea.skew,
            matrixTransform: textArea.transform,
            itemTransforms: textArea.transforms,
        });
        // TODO: We need to fix this for text path since that has different behavior. Transforms on text path transform each of the paths at each
        // step and then gets the bounding box, while transforms on normal text only operate on the 4 points of the position box.
        globalTransform = Matrix.multiply(globalTransform, buildItemTransform({ transforms: textArea.transforms, bounds: areaBounds, container: parentBounds }));
        const clipTransform = buildTransform({ bounds: areaBounds, rotationAngle: textArea.rotationAngle, itemTransforms: textArea.transforms });
        if (textArea.background !== undefined) {
            if (textArea.textPath !== undefined) {
                throw new Error("Backgrounds on text along a path not currently supported in Fusion");
            }
            const backgroundOperation = generateBackgroundShape({
                lineInfos: (_b = result.lineInfos) !== null && _b !== void 0 ? _b : [],
                position: textArea.position,
                cdifSpec: textArea.background,
                transform: globalTransform,
                colorPalette: options.colorPalette,
            });
            if (backgroundOperation) {
                operations.push(backgroundOperation);
                const [backgroundSvgPath] = parsePathData({ pathData: backgroundOperation.path, pixelSize: 1, svgPathDataUnit: "mm" });
                backgroundPreviewBox = boundingBoxFromPath({ path: backgroundSvgPath });
            }
        }
        const sourceGlyphRuns = textPathData === undefined ? result.glyphRuns : textPathData.glyphRuns;
        for (const glyphRun of sourceGlyphRuns !== null && sourceGlyphRuns !== void 0 ? sourceGlyphRuns : []) {
            const textField = getTextFieldByBlockIndex(textFields, (_c = glyphRun.textBlockIndex) !== null && _c !== void 0 ? _c : 0);
            const font = fonts[(_d = glyphRun.fontIndex) !== null && _d !== void 0 ? _d : 0];
            // Possible if requestOutlines = false
            if (font.glyphs === undefined || glyphRun.glyphs.length === 0 || font.unitsPerEm === undefined) {
                continue;
            }
            const { fontSize, baseline: baseLine } = glyphRun;
            const fill = yield parseFill(textField.color, {
                definitionTreeNode: options.definitionTreeNode,
                itemBounds: areaBounds,
                colorPalette: options.colorPalette,
            });
            const strokeFill = textField.stroke
                ? yield parseFill(textField.stroke.color, { definitionTreeNode: options.definitionTreeNode, itemBounds: areaBounds, colorPalette: options.colorPalette })
                : undefined;
            const overprints = parseOverprints(textField.overprints, options.colorPalette);
            // Only premultiply transforms for text fields with paints. Premultiplying is more expensive and unnecessary in most cases
            const premultiply = (fill === null || fill === void 0 ? void 0 : fill.type) !== "color" || (strokeFill !== undefined && strokeFill.type !== "color");
            for (const glyph of glyphRun.glyphs) {
                const { xOffset: offsetX, yOffset: offsetY } = glyph;
                const fontScalar = fontSize / font.unitsPerEm;
                let transform;
                if (glyphRun.orientation === "vertical") {
                    // Apply baseline to X instead of Y
                    transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX !== null && offsetX !== void 0 ? offsetX : 0) + positionX + baseLine, (offsetY !== null && offsetY !== void 0 ? offsetY : 0) + positionY);
                }
                else {
                    transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX !== null && offsetX !== void 0 ? offsetX : 0) + positionX, (offsetY !== null && offsetY !== void 0 ? offsetY : 0) + positionY + baseLine);
                }
                transform = Matrix.multiply(Matrix.rotation(toRadians(-glyphRun.glyphPivoting)), transform);
                let glyphPaths = font.glyphs[glyph.index].paths;
                if (premultiply && glyphPaths !== undefined) {
                    glyphPaths = svgpath(glyphPaths).matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]).toString();
                }
                if (glyphPaths) {
                    const operation = {
                        path: glyphPaths !== null && glyphPaths !== void 0 ? glyphPaths : "",
                        // Invert y scale to switch coordinate spaces (top,left -> bottom,left)
                        transform: premultiply ? globalTransform : Matrix.multiply(transform, globalTransform),
                        fill,
                        overprints,
                    };
                    // Add the stroke if it exists
                    if ((_e = textField.stroke) === null || _e === void 0 ? void 0 : _e.thickness) {
                        operation.stroke = {
                            fill: strokeFill,
                            overprints: parseOverprints(textField.stroke.overprints, options.colorPalette),
                            width: parseMM(textField.stroke.thickness) / (premultiply ? 1.0 : fontScalar),
                            lineJoin: translateLineJoin(textField.stroke),
                            lineCap: translateLineCap(textField.stroke),
                            dashArray: translateDashArray(textField.stroke),
                        };
                    }
                    operations.push(operation);
                }
            }
            if (font.fontMetrics) {
                const metrics = font.fontMetrics;
                let decorations = [];
                if (textField.decorations !== undefined && textField.decorations.length > 0) {
                    decorations = textField.decorations.slice();
                }
                // Treat legacy underline/strikeout as new definition
                else {
                    if (((_f = textField.fontStyle) === null || _f === void 0 ? void 0 : _f.includes("strikeout")) && metrics.strikeoutPosition !== undefined && metrics.strikeoutThickness !== undefined) {
                        decorations.push({ type: "strikeout" });
                    }
                    if ((_g = textField.fontStyle) === null || _g === void 0 ? void 0 : _g.includes("underline")) {
                        decorations.push({ type: "underline" });
                    }
                }
                // Newer method of specifying text decorations
                decorations.forEach((decoration) => __awaiter(this, void 0, void 0, function* () {
                    const color = decoration.color === undefined ? fill : yield parseFill(decoration.color, { colorPalette: options.colorPalette });
                    const bounds = getDecorationBounds({ type: decoration.type, metrics, glyphRun, textAreaPosition: textArea.position });
                    operations.push(buildDecorationOperation(globalTransform, bounds, bounds.height, color));
                }));
            }
        }
        if (textArea.filter != null) {
            const start = textArea.filter.indexOf("(");
            const end = textArea.filter.lastIndexOf(")");
            const filter = (_h = options.definitionTreeNode) === null || _h === void 0 ? void 0 : _h.getFilterRecursive(textArea.filter.slice(start + 1, end));
            let blackBox;
            if (textArea.textPath === undefined) {
                blackBox = {
                    left: ((_k = (_j = result.blackBoxBounds) === null || _j === void 0 ? void 0 : _j.x) !== null && _k !== void 0 ? _k : 0) + positionX,
                    top: ((_m = (_l = result.blackBoxBounds) === null || _l === void 0 ? void 0 : _l.y) !== null && _m !== void 0 ? _m : 0) + positionY,
                    width: (_p = (_o = result.blackBoxBounds) === null || _o === void 0 ? void 0 : _o.width) !== null && _p !== void 0 ? _p : 0,
                    height: (_r = (_q = result.blackBoxBounds) === null || _q === void 0 ? void 0 : _q.height) !== null && _r !== void 0 ? _r : 0,
                };
            }
            else {
                blackBox = Object.assign(Object.assign({}, areaBounds), { left: areaBounds.left + positionX, top: areaBounds.top + positionY });
            }
            if (filter !== undefined) {
                const filterResponse = processFilters({
                    filterSpecification: filter,
                    operations: operations,
                    blackBox: textArea.textPath === undefined ? maximum([blackBox, ...extraBounds]) : areaBounds,
                    originalPosition: areaBounds,
                    colorPalette: options.colorPalette,
                });
                operations = filterResponse.operations;
                filterBounds = filterBounds.concat(filterResponse.extraBounds);
            }
        }
        return { operations, transform: globalTransform, filterBounds, clipTransform, backgroundPreviewBox };
    });
}
function buildDecorationOperation(transform, bounds, thickness, color) {
    return {
        path: `M${bounds.left + thickness / 2} ${bounds.top + bounds.height / 2}h${bounds.width - thickness}`,
        transform,
        stroke: { fill: color, width: thickness, lineJoin: "bevel", lineCap: "round", dashArray: [] },
    };
}
