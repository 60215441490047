export function imageValidation({ image, decoTech }) {
    // Always allow temp urls like blob:...
    if (image.previewUrl && !image.previewUrl.startsWith("http")) {
        return { status: "pass" };
    }
    // Fallback only for embroidery when printUrl has a value
    if (decoTech === "embroidery" && image.printUrl) {
        return { status: "fail", error: "Embroidery is not supported!" };
    }
    if (image.specialProcessing) {
        // TODO: Actually implement legacyFinishMask
        if (image.specialProcessing.find((sp) => sp !== "legacyFinishMask")) {
            return { status: "fail", error: "Special processing is not supported!" };
        }
    }
    if (image.effects && image.effects.length > 0) {
        for (const effect of image.effects) {
            if (effect.type !== "colorMatrix") {
                return { status: "fail", error: "Image effects on images are not supported" };
            }
        }
    }
    return { status: "pass" };
}
