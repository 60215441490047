var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { log } from "../utils/log";
import { timestamp } from "../utils/time";
import { generateAnimationFunction } from "./helpers/Animation";
import { imageLayout } from "./image/Layout";
import { shapeLayout } from "./shapes/Layout";
import { subpanelLayout } from "./subpanel/Layout";
import { textAreaLayout } from "./text/Layout";
import { playableVideoLayout } from "./video/PlayableLayout";
import { staticVideoLayout } from "./video/StaticLayout";
import { ornamentLayout } from "./ornament/Layout";
import { itemReferenceLayout } from "./itemReference/Layout";
import CimDocDefinitionTreeNode from "../utils/CimDocDefinitionTreeNode";
import { MissingPantoneColorsError, MissingSpotColorsError, fetchPantoneColors, fetchSpotColors } from "@mcp-artwork/color-converter";
import { setColorConverterFetchMethod } from "../preFetch";
export function layoutItem(options) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { itemInfo, fontRepositoryUrl, textOptions, imageOptions, parentBounds, trackTime, enableLog, decoTech, validateAndLayout, definitionTreeNode, videoOptions, referrer, previewType, colorPalette, } = options;
        try {
            const { depth, itemType, item } = itemInfo;
            const itemId = (_a = item.id) !== null && _a !== void 0 ? _a : item.type;
            log({ message: `layout element ${itemType} ${itemId}`, enabled: enableLog, objects: Object.assign(Object.assign({}, options), { item, decoTech }) });
            const start = timestamp(trackTime);
            let result = undefined;
            if (itemType === "image") {
                result = yield imageLayout({
                    image: item,
                    parentBounds,
                    overprints: options.overprints,
                    imageOptions,
                    referrer,
                    previewType,
                    colorPalette,
                    fontRepositoryUrl,
                    validateAndLayout,
                    definitionTreeNode,
                });
            }
            if (itemType === "video") {
                if (videoOptions === null || videoOptions === void 0 ? void 0 : videoOptions.enableVideo) {
                    switch (videoOptions.mode) {
                        case "video":
                            result = yield playableVideoLayout({
                                video: item,
                                parentBounds,
                                experimentalOptions: options.experimentalOptions,
                                overprints: options.overprints,
                            });
                            break;
                        default:
                            result = yield staticVideoLayout({
                                video: item,
                                parentBounds,
                                overprints: options.overprints,
                                referrer,
                                previewType,
                                colorPalette: options.colorPalette,
                            });
                            break;
                    }
                }
                else {
                    throw Error("Video is not enabled");
                }
            }
            if (itemType === "itemReference") {
                result = yield itemReferenceLayout({
                    itemReference: item,
                    validateAndLayout,
                    parentBounds,
                    previewType,
                    colorPalette,
                    definitionTreeNode,
                    fontRepositoryUrl,
                });
            }
            if (itemType === "shape") {
                result = yield shapeLayout({
                    shape: item,
                    decoTech,
                    parentBounds,
                    options: { definitionTreeNode, fontRepositoryUrl, colorPalette },
                    previewType,
                });
            }
            if (itemType === "textArea") {
                if (fontRepositoryUrl === undefined) {
                    throw Error("Font repository is undefined");
                }
                result = yield textAreaLayout({
                    textArea: item,
                    textOptions,
                    fontRepositoryUrl,
                    decorationTechnology: decoTech,
                    parentBounds,
                    trackTime,
                    enableLog,
                    options: { definitionTreeNode, colorPalette },
                    previewType,
                });
            }
            if (itemType === "ornament") {
                result = yield ornamentLayout({
                    ornament: item,
                    parentBounds,
                    imageOptions,
                    referrer,
                    previewType,
                    colorPalette,
                });
            }
            if (itemType === "subpanel") {
                let defTreeNode = definitionTreeNode;
                if (item.definitions !== undefined) {
                    if (defTreeNode !== undefined) {
                        const childNode = defTreeNode.createChildNode(item.definitions);
                        defTreeNode = childNode;
                    }
                    else {
                        defTreeNode = new CimDocDefinitionTreeNode(item.definitions, undefined);
                    }
                }
                const layoutElements = yield validateAndLayout({
                    surfaceOrSubpanel: item,
                    definitionTreeNodeOverride: defTreeNode,
                    previewTypeOverride: "document",
                    colorPaletteParent: colorPalette,
                });
                defTreeNode = defTreeNode === null || defTreeNode === void 0 ? void 0 : defTreeNode.parent;
                result = yield subpanelLayout({
                    layoutElements,
                    parentBounds,
                    subpanel: item,
                    options: { definitionTreeNode: defTreeNode },
                    previewType,
                });
            }
            if (!result) {
                if (itemType === "ornament") {
                    log({ message: `layout element for ornament ${itemId} skipped`, enabled: enableLog, objects: { item } });
                    return undefined; // Skipped ornament type
                }
                throw Error("An item selector must have an id or type!");
            }
            let animationFunction = undefined;
            if (itemType !== "ornament" && (videoOptions === null || videoOptions === void 0 ? void 0 : videoOptions.enableVideo) && (videoOptions === null || videoOptions === void 0 ? void 0 : videoOptions.mode) === "video") {
                animationFunction = generateAnimationFunction(item, parentBounds, result);
            }
            log({ message: `layout element ${itemType} ${itemId} done`, enabled: enableLog, objects: result });
            return {
                depth,
                value: Object.assign(Object.assign(Object.assign({}, result), { renderingOperation: Object.assign(Object.assign({}, result.renderingOperation), (animationFunction ? { getAnimation: animationFunction } : {})) }), (start
                    ? {
                        debugInfo: {
                            timers: {
                                total: timestamp(true) - start,
                            },
                        },
                    }
                    : {})),
            };
        }
        catch (error) {
            if (error instanceof MissingPantoneColorsError) {
                log({ message: "missing pantone colors, fetching file...", enabled: enableLog, objects: { error } });
                setColorConverterFetchMethod();
                yield fetchPantoneColors();
                log({ message: "done fetching pantone colors", enabled: enableLog });
                return layoutItem(options);
            }
            if (error instanceof MissingSpotColorsError) {
                log({ message: "missing spot colors, fetching file...", enabled: enableLog, objects: { error } });
                setColorConverterFetchMethod();
                yield fetchSpotColors();
                log({ message: "done fetching spot colors", enabled: enableLog });
                return layoutItem(options);
            }
            throw error;
        }
    });
}
