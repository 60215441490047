export function mergeColorPalletes(palettes) {
    return palettes.filter(Boolean).reduce((acc, palette) => {
        return Object.assign(Object.assign({}, acc), { palette: Object.assign(Object.assign({}, acc.palette), ((palette === null || palette === void 0 ? void 0 : palette.palette) || {})) });
    }, { palette: {} });
}
export function replaceColorOverrides(colorPalette = { palette: {} }, colorOverrides = []) {
    // Create a new color palette with transformed entries
    const newColorPalette = {
        palette: Object.fromEntries(Object.entries(colorPalette.palette).map(([key, value]) => {
            // Create a new color entry
            const newValue = Object.assign({}, value);
            // Find matching color override by ordinal
            const colorOverride = colorOverrides.find((co) => co.ordinal === value.ordinal);
            // Apply the override if found
            if (colorOverride) {
                newValue.color = colorOverride.color;
            }
            // Return the new key-value pair
            return [key, newValue];
        })),
    };
    return newColorPalette;
}
