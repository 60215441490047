import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { CSRvsSSR } from "./CSRvsSSR";
import { VortexDemo } from "./VortexDemo/VortexDemo";
import { ItemPreviews } from "./ItemPreviews";
import { Preview } from "./Preview";
import { TextDemoPage } from "./TextDemoPage";
import { DocumentRenderingPage } from "./DocumentRenderingPage";
import { GlobalSettings, GlobalSettingsOptions } from "./GlobalSettings";
import { StorageHooks, useLocalStorage } from "./hooks/useLocalStorage";
import { defaultGlobalOptions, GlobalOptionsContext } from "./Context/GlobalOptionsContext";
import { RichMediaDocumentRenderingPage } from "./RichMediaDocumentRenderingPage";
import { Designer } from "./Designer";
import { getAuth } from "./auth";
import { ItemPreviewMetadata } from "./ItemPreviewMetadata";
import "./index.css";
import { MeasurementsPage } from "./MeasurementsPage";

const storageHooks: StorageHooks<GlobalSettingsOptions> = {
  beforeWrite(obj: GlobalSettingsOptions): any {
    return obj;
  },
  afterRead(obj): GlobalSettingsOptions | null {
    // Emit legacy initRtext
    const { initRText, ...rest } = obj;
    return rest;
  },
};

const InnerApp = () => {
  const [options, setOptions] = useLocalStorage<GlobalSettingsOptions>("fusion-global-settings", defaultGlobalOptions, storageHooks);
  const [showOptions] = useState<boolean>(true);

  useEffect(() => {
    if (!getAuth().isLoggedIn()) {
      getAuth()
        .ensureAuthentication({ nextUri: window.location.pathname + window.location.hash })
        .then((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            window.location.reload();
          }
        });
    }

    getAuth().on("tokenExpired", () => {
      getAuth().login({ nextUri: window.location.pathname + window.location.hash, forceLogin: true });
    });
  }, []);

  if (!getAuth().isLoggedIn()) {
    return <div></div>;
  }

  return (
    <React.StrictMode>
      {showOptions && <GlobalSettings onChange={setOptions} options={options} />}
      <GlobalOptionsContext.Provider value={{ options, setOptions }}>
        <HashRouter>
          <Routes>
            <Route path="/designer" element={<Designer />} />
            <Route path="/" element={<AppLayout />}>
              <Route index element={<ItemPreviews />} />
              <Route path="/document-rendering">
                <Route path=":url" element={<DocumentRenderingPage />} />
                <Route path="" element={<DocumentRenderingPage />} />
              </Route>
              <Route path="/rich-media-document-rendering">
                <Route path=":url" element={<RichMediaDocumentRenderingPage />} />
                <Route path="" element={<RichMediaDocumentRenderingPage />} />
              </Route>
              <Route path="/text" element={<TextDemoPage />} />
              <Route path="/csr-vs-ssr" element={<CSRvsSSR />} />
              <Route path="/vortex-demo" element={<VortexDemo />} />
              <Route path="/measurements" element={<MeasurementsPage />} />
              <Route path="*" element={<p>404, nothing to see here.</p>} />
            </Route>
          </Routes>
        </HashRouter>
      </GlobalOptionsContext.Provider>
    </React.StrictMode>
  );
};

const App = () => {
  if (location.hash.startsWith("#/preview")) {
    return <Preview />;
  }
  if (location.hash.startsWith("#/itemPreviewMetadata")) {
    return <ItemPreviewMetadata />;
  }

  return <InnerApp />;
};

ReactDOM.render(<App />, document.getElementById("root"));
