import { TileAugmenter } from "./TileAugmenter";
export class QueueAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.tileWidth = this.defpanelWidth * 2;
        this.tileHeight = this.defpanelHeight * 2;
    }
    /* Queue Tile Pattern will be as follows
     *---pattern-vert&horiz mirror---*------pattern-vert-mirror-----*
     *------------patern-------------*------pattern-horz-mirror_____*
     */
    augmentTilePatterns() {
        const patternWidth = this.defpanelWidth;
        const patternHeight = this.defpanelHeight;
        const vertical = "vertical";
        const horizontal = "horizontal";
        const pattern1Position = this.createBounds(0, 0, patternWidth, patternHeight);
        this.augmentPattern(pattern1Position, 1, vertical);
        const pattern2Position = this.createBounds(patternWidth, 0, patternWidth, patternHeight);
        this.augmentPattern(pattern2Position, 1, vertical);
        const pattern3Position = this.createBounds(0, patternHeight, patternWidth, patternHeight);
        this.augmentPattern(pattern3Position, 1, horizontal);
        const pattern4Position = this.createBounds(patternWidth, patternHeight, patternWidth, patternHeight);
        this.augmentPattern(pattern4Position);
    }
    getTileHeight() {
        return this.tileHeight;
    }
    getTileWidth() {
        return this.tileWidth;
    }
}
